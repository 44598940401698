"use client";

import Image from 'next/image';
import { sanityClient } from 'lib/sanity.server';
import { useNextSanityImage } from 'next-sanity-image';
import styles from './sanity-image.module.scss';
import { PlaceholderValue } from 'next/dist/shared/lib/get-img-props';
import { ImageAsset, Image as ISanityImageType, Reference } from '@sanity/types';
const SanityImage = ({
  alt,
  image,
  className = "",
  sizes = "100vw",
  priority = false,
  quality = 75,
  placeholder = "empty",
  fill = false,
  style
}: ISanityImage) => {
  const imageProps = useNextSanityImage(sanityClient({}), image);
  if (!image) {
    return null;
  }
  const altText = alt || (image.alt as string);
  //This is a bit weird but it lets us use the Sanity Provided type for Image everywhere else(which defines asset as a Reference)
  //For the purpose of this component the asset reference should be reslolved with the groqImageResolver.

  const asset = (image.asset as ImageAsset);
  const palette = asset?.metadata?.palette?.dominant || null;
  if (!palette && process.env.NODE_ENV === "development") {
    console.warn(`An image on this page was not resolved using groqImageResolver \n
		This means alt text authored in the media library is ignored, \n
		and the image won't have the correct loading background color.`);
  }
  if (!image) {
    return null;
  }
  const colorStyles = palette ? {
    "--img-bg-color": palette.background,
    "--img-color": palette.foreground
  } : {};
  const defaultCSS = {
    maxWidth: '100%',
    height: 'auto',
    ...colorStyles,
    ...style
  };
  const defaultFillCSS = {
    objectFit: "cover",
    ...colorStyles,
    ...style
  };
  const handleImageLoaded = event => {
    event?.target?.removeAttribute("data-loading");
  };
  return <Image className={`${styles.img} ${className}`} alt={altText || " "} style={fill ? defaultFillCSS : defaultCSS} {...imageProps} fill={fill} quality={quality} data-loading={true} placeholder={placeholder} priority={priority} sizes={sizes} onLoad={handleImageLoaded} width={fill ? 0 : imageProps.width} height={fill ? 0 : imageProps.height} data-sentry-element="Image" data-sentry-component="SanityImage" data-sentry-source-file="index.tsx" />;
};
interface ISanityImage {
  alt?: string;
  className?: string;
  fill?: boolean;
  image: Omit<ISanityImageType, "asset"> & {
    asset?: ImageAsset | Reference;
  };
  placeholder?: PlaceholderValue | undefined;
  priority?: boolean;
  quality?: number;
  sizes: string;
  style?: any;
}
export default SanityImage;