import SanityImage from "@sanity-image";
import Link from "next/link";
import styles from './image.module.scss';
import RichTextInline from "components/rich-text/rich-text-inline-only";
import type { Image } from "@sanity/types";
const PTImage = ({
  value
}: {
  value: IPortableTextImage;
}) => {
  if (!value?.asset) {
    return null;
  }
  const maxWidth = value?.maxWidth || "100%";
  return <div className={value.extraLarge ? "extraLarge" : styles.imageWrapper} data-sentry-component="PTImage" data-sentry-source-file="index.tsx">
			<figure style={{
      maxWidth: maxWidth
    }} className={styles.figure}>			
				{value.link ? <Link href={value.link}>
						<SanityImage alt={value.alt} sizes="(max-width: 960px) 85vw, 1200px" image={value} />
					</Link> : <SanityImage alt={value.alt} sizes="(max-width: 960px) 85vw, 1200px" image={value} />}

				{value.imageCaption && <figcaption className={styles.imgCaption}>
						<RichTextInline value={value.imageCaption} />
					</figcaption>}
			</figure>
		</div>;
};
interface IPortableTextImage extends Image {
  alt?: string;
  extraLarge?: boolean;
  imageCaption?: string;
  link?: string;
  maxWidth?: number;
}
export default PTImage;