import { SUPPORTED_LANGUAGES } from "lib/constants.mjs";
import PropTypes from 'prop-types';
import styles from "components/audio-guide/audio-guide.module.scss";
import { useLocale } from 'next-intl';
import { useTranslations } from "next-intl";
const I8nBadge = props => {
  const {
    languages = [{
      language: 'en'
    }],
    text,
    enableDarkMode = false
  } = props;

  // Corrects for a quirk in how translations are implemented. For a document with no non-English translations
  // the metadata document does not exist and the query for it will produce an empty array. The default 
  // case is English only.
  const availableLanguages = languages?.length ? languages : [{
    language: 'en'
  }];
  const localLanguagesUnfiltered = availableLanguages.map(language => {
    return SUPPORTED_LANGUAGES.find(supportedLanguage => supportedLanguage.id == language?.language)?.localName;
  }).filter(x => !!x); // strip out any languages not found
  const localLanguages = [...new Set(localLanguagesUnfiltered)];
  const languageString = localLanguages.join(', ');
  const t = useTranslations();
  const currentLang = useLocale() || "en"; // mostly for storybook because it doesn't have a NextJS language context
  const availableInText = text ? text : t('onlyAvailableIn');
  const badgeClassName = enableDarkMode ? `${styles.playlistSlideTranslations} ${styles.plalistSlideTranslationsDarkMode}` : styles.playlistSlideTranslations;
  const showBadge = !availableLanguages.find(language => language?.language == currentLang);
  if (showBadge) {
    return <div className="audio-player__badge-container">
				<div className={badgeClassName}>
					{`${availableInText} ${languageString}`}
				</div>
			</div>;
  }
};
I8nBadge.propTypes = {
  text: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.shape({
    language: PropTypes.string
  })),
  enableDarkMode: PropTypes.bool
};
export default I8nBadge;